
import { mdiClose } from "@mdi/js";
import { mdilLink } from "@mdi/light-js";
import Vue from "vue";
import { mapGetters, mapActions } from "vuex";

export default Vue.extend({
  data() {
    return {
      mdiClose,
      mdilLink,
      overlay: !!(this as any).isShareActive,
    };
  },
  computed: {
    ...mapGetters({
      isShareActive: "modal/isShareActive",
      tourURL: "modal/shareTourURL",
      tourTitle: "modal/shareTourTitle",
    }),
  },
  watch: {
    isShareActive(val) {
      this.overlay = val;
    },
  },
  methods: {
    copyURL() {
      import(
        /* webpackChunkName: "copy-text-to-clipboard" */ "copy-text-to-clipboard"
      )
        .then((copy) => {
          copy.default(this.tourURL)
            ? this.$toast.success("Copied")
            : this.$toast.error("Something happend");
        })
        .catch(() => {
          this.$toast.error("Something happend");
        });
    },
    ...mapActions({
      closeShareModal: "modal/closeShareModal",
    }),
  },
});
