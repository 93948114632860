var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-overlay',{staticClass:"px-10",attrs:{"dark":false},model:{value:(_vm.overlay),callback:function ($$v) {_vm.overlay=$$v},expression:"overlay"}},[_c('v-sheet',{attrs:{"color":"white"}},[_c('div',{staticClass:"ShareModal"},[_c('div',{staticClass:"ShareModal__body"},[_c('div',{staticClass:"ShareModal__share"},[_c('div',{staticClass:"ShareModal__shareCopy"},[_c('div',{staticClass:"ShareModal__shareCopyUrl",on:{"click":_vm.copyURL}},[_c('v-icon',{attrs:{"size":"20","color":"#aaa8b1","left":""}},[_vm._v(_vm._s(_vm.mdilLink))]),_vm._v("\n              "+_vm._s(_vm.tourURL)+"\n            ")],1),_vm._v(" "),_c('a',{staticClass:"ShareModal__shareCopyButton",on:{"click":_vm.copyURL}},[_vm._v("Copy to Clipboard")])]),_vm._v(" "),_c('div',{staticClass:"ShareModal__shareButtons"},[_c('a',{staticClass:"ShareModal__shareButton ShareModal__shareButton--facebook",attrs:{"href":'https://www.facebook.com/sharer.php?u=' +
                encodeURIComponent(_vm.tourURL) +
                '&t=' +
                encodeURIComponent(_vm.tourTitle),"rel":"nofollow noopener","target":"_blank"}},[_vm._v("Facebook")]),_vm._v(" "),_c('a',{staticClass:"ShareModal__shareButton ShareModal__shareButton--twitter",attrs:{"href":'https://twitter.com/intent/tweet?url=' +
                encodeURIComponent(_vm.tourURL) +
                '&text=' +
                encodeURIComponent(_vm.tourTitle),"rel":"nofollow noopener","target":"_blank"}},[_vm._v("Tweet")]),_vm._v(" "),_c('a',{staticClass:"ShareModal__shareButton ShareModal__shareButton--line",attrs:{"href":'https://social-plugins.line.me/lineit/share?url=' +
                encodeURIComponent(_vm.tourURL) +
                '&text=' +
                encodeURIComponent(_vm.tourTitle),"rel":"nofollow noopener","target":"_blank"}},[_vm._v("LINE")]),_vm._v(" "),(_vm.$i18n.locale == 'zh-Hans')?_c('a',{staticClass:"ShareModal__shareButton ShareModal__shareButton--weibo",attrs:{"href":'http://service.weibo.com/share/share.php?url=' +
                encodeURIComponent(_vm.tourURL) +
                '&title=' +
                encodeURIComponent(_vm.tourTitle) +
                '&type=icon&style=simple&language=zh_cn',"rel":"nofollow noopener","target":"_blank"}},[_vm._v("Weibo")]):_vm._e()])])])]),_vm._v(" "),_c('v-btn',{staticClass:"font-weight-bold",attrs:{"color":"primary","text":"","x-large":"","block":""},on:{"click":_vm.closeShareModal}},[_vm._v(_vm._s(_vm.$t("button.close"))),_c('v-icon',{attrs:{"medium":""}},[_vm._v(_vm._s(_vm.mdiClose))])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }